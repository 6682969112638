.textarea-no-scroll::-webkit-scrollbar {
  display: none;
}

.textarea-no-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.copy-success {
  color: green;
}